import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { slide as Menu } from "react-burger-menu"

const links = {
  // "/": "Home",
  "/about": {
    text: "About",
    subnav: {
      "/essex-capital-partners": {
        text: "Essex Capital Partners",
      },
      "/mitchell-b-rutter": {
        text: "Mitchell B. Rutter",
      },
      "/select-projects": {
        text: "Select Projects",
      },
    },
  },
  "/capabilities": {
    text: "Capabilities",
    subnav: {
      "/development": {
        text: "Development",
      },
      "/asset-repositioning": {
        text: "Asset Repositioning",
      },
      "/acquisition": {
        text: "Financing & Acquisition",
      },
      "/partnerships": {
        text: "Public/Private Partnerships",
      },
    },
  },
  "/case-studies": {
    text: "Case Studies",
    subnav: {
      "/huntsville-alabama-downtown": {
        text: "Huntsville Alabama Downtown",
      },
      "/500-tenth-avenue": {
        text: "500 Tenth Avenue",
      },
      "/2-dag-hammerskjold-plaza": {
        text: "2 Dag Hammerskjold Plaza",
      },
      "/1500-broadway": {
        text: "1500 Broadway",
      },
      "/63-schermerhorn-street": {
        text: "63 Schermerhorn Street",
      },
      "/636-eleventh-avenue": {
        text: "636 Eleventh Avenue",
      },
      "/rockville-center": {
        text: "Rockville Town Center",
      },
    },
  },
  "/media": {
    text: "Media",
  },
  "/contact": {
    text: "Contact",
  },
  "/login": {
    text: "Investor Login",
  },
}

const Header = () => {
  const [value, setValue] = useState("")

  const handleMouseOver = l => setValue(l)
  const handleMouseOut = () => setValue("")
  return (
    <>
        <StyledHeader
          style={{
            background: `white`,
          }}
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              <Logo />
            </Link>
          </h1>
          <nav>
            <ul>
              {Object.keys(links).map(l => (
                <li
                  onMouseEnter={() => handleMouseOver(l)}
                  onMouseLeave={() => handleMouseOut()}
                >
                  <Link to={l}>{links[l].text}</Link>
                  {links[l].subnav && value === l && (
                    <ul className="subnav">
                      {Object.keys(links[l].subnav).map(sn => (
                        <li key={links[l].subnav[sn].text}>
                          <Link to={l + sn}>{links[l].subnav[sn].text}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </StyledHeader>
        <MobileHeader />
    </>
  )
}

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: 15% 80%;
  grid-gap: 0% 5%;
  grid-template-rows: auto;
  margin: 1.45rem auto;
  max-width: 1180px;
  align-items: center;
  padding: 1.45rem 2.0875rem;
  font: 100 15px ${props => props.theme.fonts.sans};
  color: #425c6a;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
  .subnav {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 100;
    padding: 0.3rem 1rem 1rem;
    li {
      margin-top: 1rem;
      a {
      }
    }
  }
  nav {
    text-transform: uppercase;
    grid-column-start: 2;
    ul {
      position: relative;
      display: grid;
      grid-template-columns: 0.7fr 1fr 1fr 0.7fr 0.8fr 1fr;
      font-size: 14px;
      grid-template-rows: auto;
      align-items: end;
      justify-items: center;
      list-style: none;
      margin: 0;
      li {
        a {
          text-decoration: none;
          color: ${props => props.theme.colors.primary};
          white-space: nowrap;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: ${props => props.theme.colors.primary};
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
          }

          &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }
      }
    }
  }
`

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}
const StyledMobileHeader = styled.header`
  top: 0;
  height: 100px;
  z-index: 100;
  width: 100%;
  display: none;

  font: 100 18px ${props => props.theme.fonts.sans};
  a { 
    color: white;
    text-transform: uppercase;
    text-decoration: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 35px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    width: 100%;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
  }

  .logo-container {
    max-width: 200px;
    margin: 0 auto;
    position: relative;
    top: 35px;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      max-width: 150px;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      max-width: 140px;
      margin-left: 2rem;
    }
  }

  .menu-container {
    top: 0;
    position: absolute;
  }

  li {
    list-style: none;
    margin-top: 1rem;
  }
`

const MobileHeader = () => (
  <StyledMobileHeader
    style={{
      background: `white`,
    }}
  >
    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          textDecoration: `none`,
        }}
      >
        <div className="logo-container">
          <Logo />
        </div>
      </Link>
    </h1>
    <Menu className="menu-container" right>
      {Object.keys(links).map(l => (
        <li>
          <Link className="menu-item" to={l}>{links[l].text}</Link>
          {/* {links[l].subnav && (
            <ul className="subnav">
              {Object.keys(links[l].subnav).map(sn => (
                <li key={links[l].subnav[sn].text}>
                  <Link className="menu-item" to={l + sn}>{links[l].subnav[sn].text}</Link>
                </li>
              ))}
            </ul>
          )} */}
        </li>
      ))}
    </Menu>
  </StyledMobileHeader>
)


export default Header
