/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"

import Header from "./header"
import styled from "@emotion/styled"
import "./layout.css"

const theme = {
  colors: {
    primary: "#425c6a",
    secondary: "#2b2b2b",
  },
  fonts: {
    sans: "Proxima Nova, Helvetica, Arial, sans-serif",
    serif: "Cormorant Garamond, Times New Roman, Times, serif",
    accent: "Roboto Mono, Helvetica, Arial, sans-serif",
  },
  lineHeight: {
    summary: '35px',
    summaryTablet: '30px !important',
    paragraph: '26px !important'
  },
  fontSize: {
    header: "50px",
    paragraph: "20px",
    summary: "25px",
    summaryTablet: "20px",
    small: "14px",
    subheader: "30px",
  },
  breakpoints: {
    tablet: '720px',
    phone: '400px'
  }
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <LayoutContainer>
        <main>{children}</main>
        <footer>© Essex Capital, {new Date().getFullYear()}</footer>
      </LayoutContainer>
    </ThemeProvider>
  )
}

const LayoutContainer = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 2rem 1.45rem;
  color: #425c6a;
  footer {
    font-size: ${props => props.theme.fontSize.small};
  }
}

`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
